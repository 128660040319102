import(/* webpackMode: "eager" */ "C:\\Users\\carro\\OneDrive\\Documents\\Programs\\Websites\\tic-tac-toe\\node_modules\\next\\dist\\client\\components\\client-page.js");
;
import(/* webpackMode: "eager" */ "C:\\Users\\carro\\OneDrive\\Documents\\Programs\\Websites\\tic-tac-toe\\node_modules\\next\\dist\\client\\components\\client-segment.js");
;
import(/* webpackMode: "eager" */ "C:\\Users\\carro\\OneDrive\\Documents\\Programs\\Websites\\tic-tac-toe\\node_modules\\next\\dist\\client\\components\\error-boundary.js");
;
import(/* webpackMode: "eager" */ "C:\\Users\\carro\\OneDrive\\Documents\\Programs\\Websites\\tic-tac-toe\\node_modules\\next\\dist\\client\\components\\http-access-fallback\\error-boundary.js");
;
import(/* webpackMode: "eager" */ "C:\\Users\\carro\\OneDrive\\Documents\\Programs\\Websites\\tic-tac-toe\\node_modules\\next\\dist\\client\\components\\layout-router.js");
;
import(/* webpackMode: "eager" */ "C:\\Users\\carro\\OneDrive\\Documents\\Programs\\Websites\\tic-tac-toe\\node_modules\\next\\dist\\client\\components\\render-from-template-context.js");
;
import(/* webpackMode: "eager" */ "C:\\Users\\carro\\OneDrive\\Documents\\Programs\\Websites\\tic-tac-toe\\node_modules\\next\\dist\\lib\\metadata\\metadata-boundary.js");
